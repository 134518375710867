interface Error404Props {
    redirect: string

}

const Error404 : React.FC<Error404Props> =  ({ redirect  }) => {
    return (
        <></>
    )
}

export default Error404