import React, { useEffect, useState, useContext } from "react";
import useHeaderFooter from "../../hooks/useHeaderFooter";
import { useParams, useNavigate } from "react-router-dom";
import { useCourseOutline } from "../../hooks/useCourseOutline";
import CourseMap from "../../components/CourseMap/CourseMap";
import { addIframeUrl, extractContents, getFirstUnitId, getNextUnitId } from "../../hooks/utils/courseOutline";
import '../../css/CourseWorking.css';
import { AppDataContext } from "@iblai/ibl-web-react-common";

import CourseNavigation from "../../components/CourseNavigation/CourseNavigation";
import { getNextCourseId, saveUserMetaData } from "../../hooks/utils/helpers";
import IframeResizer from "@iframe-resizer/react";
import WhyLeadDynamicSvg from "./svgProgressLoader/WhyLeadDynamicSvg";
import LeadingOthersDynamicSvg from "./svgProgressLoader/LeadingOthersDynamicSvg";
import LeadingFowardDynamicSvg from "./svgProgressLoader/LeadingFowardDynamicSvg";
import LeadingSelfDynamicSvg from "./svgProgressLoader/LeadingSelfDynamicSvg";
import LeadingChangeDynamicSvg from "./svgProgressLoader/LeadingChangeDynamicSvg";
import useCourseCompletion from "../../hooks/useCourseCompletion";
import useCoursePreview from "../../hooks/useCoursePreview";
interface CourseWorkingProps {
    children?: React.ReactNode;
}

const CourseWorking: React.FC<CourseWorkingProps> = ({ children }) => {
    const { headerContent, footerContent } = useHeaderFooter(children);
    const { courseID, unitID } = useParams<{ courseID: string; unitID: string }>();
    const [currentUnitID, setCurrentUnitID] = useState<string>("");
    const [iframeURL, setIframeURL] = useState<string>("");
    const [iframeLoading, setIframeLoading] = useState<boolean>(true);
    const { appData, setAppData } = useContext(AppDataContext);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [refresher, setRefresher] = useState(0)
    const LMS_BASE_URL = process.env.REACT_APP_IBL_LMS_URL

    const {
        courseInfo,
    } = useCoursePreview(courseID);

    const {
        userCourseCompletions,
        userCourseCompletionLoaded,
    } = useCourseCompletion(unitID, courseID);

   

    const navigate = useNavigate();
    const {
        userCourseOutline,
        userCourseOutlineLoaded,
        hasFetchedData
    } = useCourseOutline(courseID || "", refresher);

    useEffect(() => {
        //  save current course ID to userMetaData
        const userMetaData: any = {
            //...appData.userMetaData,
            username: appData.userMetaData.username,
            user_fullname: appData.userData.user_fullname,
            ...(appData.userMetaData.bio ? {bio: appData.userMetaData.bio}  : {} ),
            ...(appData.userMetaData?.social_links?.length > 0 ?
                {social_links: appData.userMetaData.social_links}  : {}),
            public_metadata: {
                ...appData.userMetaData.public_metadata,
                current_course: courseID,
            }
        };
        saveUserMetaData(userMetaData, (response: any) => {



        }, (error: any) => {

            console.log(error);
        });




    }, [courseID, unitID]);

    useEffect(() => {
        if (userCourseOutlineLoaded && courseID) {
            const initialUnitID = unitID || getFirstUnitId(extractContents(userCourseOutline));
            setCurrentUnitID(initialUnitID);
            setIframeLoading(true);
            addIframeUrl(initialUnitID, (iframeURL: string) => {
                setIframeURL(iframeURL);
                setIframeLoading(false);
            }, courseID);
        }
    }, [courseID, unitID, userCourseOutlineLoaded, userCourseOutline]);

    const handleNavigateToUnit = (unitId: string) => {
        if (courseID) {
            navigate(`/course-working/${courseID}/${unitId}`);
        }
    };

    const handleRefreshOutline = () => {
        hasFetchedData.current = false
        setTimeout(()=>{
            setRefresher(Date.now())
        }, 200)
    }

    const handleNextUnit = () => {
        const nextUnitId = getNextUnitId(extractContents(userCourseOutline), currentUnitID);
        if (nextUnitId !== currentUnitID) {
            handleRefreshOutline()
            handleNavigateToUnit(nextUnitId);
        }
    };

    const handlePreviousUnit = () => {
        const contents = extractContents(userCourseOutline);
        const currentIndex = contents.findIndex(content => content.id === currentUnitID);
        if (currentIndex > 0) {
            handleRefreshOutline()
            const previousUnitId = contents[currentIndex - 1].id;
            handleNavigateToUnit(previousUnitId);
        }
    };

    const isFirstUnit = currentUnitID === getFirstUnitId(extractContents(userCourseOutline));
    const isLastUnit = currentUnitID === getNextUnitId(extractContents(userCourseOutline), currentUnitID);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };


    const handleNextCourse = () => {
        const nextCourseId = getNextCourseId(courseID || "");
        if (nextCourseId !== courseID) {
            handleRefreshOutline()
            navigate(`/course-overview/${nextCourseId}`);
        }
    };

    return (
        <>
            <input
                type="hidden"
                id="anPageName"
                name="page"
                defaultValue="course-progress"
            />
            <div className="container-center-horizontal">
                <div className="course-progress screen">
                    <div className="overlap-group8">
                        <div className=" overlap-group6 overlap-group6-custom">
                            <div className="hero-image">
                                <div className="overlap-group">
                                    <img
                                        className="inbal-marilli-lg-ui"
                                        src={`${LMS_BASE_URL}${courseInfo?.banner_image_asset_path}`}
                                    />
                                    <div className="rectangle-20" />
                                </div>
                            </div>
                            <div className="course-headline">
                                <h1 className="course-01-why-lead instrumentserif-normal-white-100-1px-2">
                                    <span className="instrumentserif-normal-white-100-1px">
                                        Module
                                        <br />
                                    </span>
                                    <span className="recklessneue-regular-normal-white-100-1px c_title">
                                        {"display_name" in userCourseOutline ? userCourseOutline.display_name : ""}
                                   
                                    </span>
                                </h1>
                            </div>
                        </div>
                        {headerContent}


                        {userCourseCompletionLoaded && (

                            <div
                                style={{
                                    height: isExpanded ? 'auto' : '50px',
                                }}
                                className="course-progress-container"
                                onClick={toggleExpand}
                            >
                                {isExpanded ? (

                                    <>
                                        <div className="oxford instrumentsans-normal-white-24px" style={{ marginTop: "0", marginBottom: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            Collapse full course progress
                                            <img src="/img/progress_collapse.png" style={{ width: "30px", height: "30px", marginLeft: "10px" }} />
                                        </div>

                                        <div className="course-progress-svg-container">
                                            {
                                                <>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        {WhyLeadDynamicSvg({ overlayWidth: "course-v1:Legatum+101+2023" in userCourseCompletions ? userCourseCompletions["course-v1:Legatum+101+2023"]?.completion_percentage : 0 })}
                                                        <span style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>01:Why <br></br> Lead</span>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        {LeadingSelfDynamicSvg({ overlayWidth: "course-v1:Legatum+103+2023" in userCourseCompletions ? userCourseCompletions["course-v1:Legatum+103+2023"]?.completion_percentage : 0 })}
                                                        <span style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>02:Leading <br></br> Self</span>
                                                    </div>

                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        {LeadingOthersDynamicSvg({ overlayWidth: "course-v1:Legatum+104+2023" in userCourseCompletions ? userCourseCompletions["course-v1:Legatum+104+2023"]?.completion_percentage : 0 })}
                                                        <span style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>03:Leading <br></br> Others</span>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        {LeadingChangeDynamicSvg({ overlayWidth: "course-v1:Legatum+106+2023" in userCourseCompletions ? userCourseCompletions["course-v1:Legatum+106+2023"]?.completion_percentage : 0 })}
                                                        <span style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>03:Leading <br></br> Change</span>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        {LeadingFowardDynamicSvg({ overlayWidth: "course-v1:Legatum+107+2023" in userCourseCompletions ? userCourseCompletions["course-v1:Legatum+107+2023"]?.completion_percentage : 0 })}
                                                        <span style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>05:Leading <br></br> Forward</span>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="oxford instrumentsans-normal-white-24px" style={{ marginTop: "0", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            Expand full course progress
                                            <img src="/img/progress_expand.png" style={{ width: "30px", height: "30px", marginLeft: "10px" }} />
                                        </div>

                                    </>
                                )}
                            </div>
                        )}

                    

                        <div id="page-nav">
                            <div className="page-nav">
                                <div className="nav-cont">
                                    <div className="progress-nav">
                                        <img
                                            className={`arrow-1 arrow nav_btn previous_btn ${isFirstUnit ? "disabled" : ""}`}
                                            src="/img/arrow-1.svg"
                                            alt="Arrow 1"
                                            onClick={!isFirstUnit ? handlePreviousUnit : undefined}
                                        />
                                        <p className="chapter-one-what-to-expect recklessneue-book-normal-congress-blue-22-8px c-active">
                                        </p>
                                        <img
                                            className={`arrow nav_btn next_btn ${isLastUnit ? "disabled" : ""}`}
                                            src="/img/arrow-2-1.svg"
                                            alt="Arrow 2"
                                            onClick={!isLastUnit ? handleNextUnit : handleNextCourse}
                                            style={{ display: (getNextCourseId(courseID || "") === "course-v1:Legatum+100+2023" && isLastUnit) ? "none" : "flex" }}
                                        />
                                    </div>
                                    <CourseNavigation courseID={courseID} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="working-main" id="working_main">
                        {userCourseOutlineLoaded &&
                            <CourseMap courseOutlineData={userCourseOutline} unitID={currentUnitID} courseID={courseID} />
                        }
                        <div className="c-iframe">
                            {userCourseOutlineLoaded && !iframeLoading ? (
                                <IframeResizer
                                    license="GPLv3"
                                    className="w-skeleton"
                                    id="edx-iframe"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox allow-downloads allow-top-navigation allow-top-navigation-by-user-activation"
                                    frameBorder={0}
                                    src={iframeURL}
                                    onLoad={() => setIframeLoading(false)}
                                    style={{
                                        width: "100%",
                                        display: "inline",
                                        overflow: "hidden",
                                    }}
                                    allowFullScreen
                                    scrolling={false}
                                ></IframeResizer>
                            ) : (
                                <div className="iframe-loader-skeleton" />
                            )}
                            <div className="bottom-arrows">
                                <div
                                    className={`flex-arrows nav_btn previous_btn ${isFirstUnit ? "disabled" : ""}`}
                                    onClick={!isFirstUnit ? handlePreviousUnit : undefined}
                                >
                                    <div className="bottom_nav ">
                                        <svg
                                            width={19}
                                            height={18}
                                            viewBox="0 0 19 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M18.9849 10.296L1.87692 10.296L2.78692 9.568L11.1069 17.94L8.50692 17.94L0.00492268 9.36L8.53292 0.779999L11.1329 0.779999L2.78692 9.152L1.87692 8.424L18.9849 8.424L18.9849 10.296Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                    Previous
                                </div>
                                <div
                                    className={`flex-arrows nav_btn next_btn`}
                                    style={{ display: (getNextCourseId(courseID || "") === "course-v1:Legatum+100+2023" && isLastUnit) ? "none" : "flex" }}
                                    onClick={!isLastUnit ? handleNextUnit : handleNextCourse}
                                >
                                    Save &amp; Continue
                                    <div className="bottom_nav ">
                                        {" "}
                                        <svg
                                            width={19}
                                            height={18}
                                            viewBox="0 0 19 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M0.0150782 7.704H17.1231L16.2131 8.432L7.89308 0.0599985H10.4931L18.9951 8.64L10.4671 17.22H7.86708L16.2131 8.848L17.1231 9.576H0.0150782V7.704Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {footerContent}
                </div>
            </div>
            <div id="loom-companion-mv3" ext-id="liecbddmkiiihnedobmlmillhodjkdmb">
                <section id="shadow-host-companion" />
            </div>
        </>
    );
}

export default CourseWorking;
