import { isLoggedIn } from '../../hooks/utils/helpers';
import './Footer.css'
import {NavLink} from "react-router-dom";

const Footer = () => {
    const currentYear: number = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="flex-col-1 flex-col-4">
                <a href="/">
                    <img className="group-7" src="/img/group-7-2@2x.png" alt="Group 7"/>
                </a>
                <div className="footer-custom">
                    a joint initiative of
                </div>
                <div className="university-logos">
                    <NavLink
                        target="_blank"
                        to="https://www.legatum.com/"
                        className="legatum_primary_logo-container d-block"
                    >
                        <img
                            className="legatum_-primary_-logo-1 legatum_-primary_-logo"
                            src="/img/legatum-primary-logo-1-7@2x.png"
                            alt="Legatum_Primary_Logo 1"
                        />
                        <img
                            className="legatum_-primary_-logo-2 legatum_-primary_-logo"
                            src="/img/legatum-primary-logo-2-6@2x.png"
                            alt="Legatum_Primary_Logo 2"
                        />
                    </NavLink>
                    <a
                        target="_blank"
                        href="https://oxfordcharacter.org/"
                        className="oxford-logo"
                    >
                        <img
                            style={{height: 75}}
                            className=""
                            src="/img/oxford-project.jpg"
                            alt="Group_2749"
                        />
                    </a>
                    <a target="_blank" href="https://www.ox.ac.uk/" className="oxford-logo">
                        <img
                            style={{height: 75}}
                            className=""
                            src="/img/oxford-university.jpg"
                            alt="Group_2749"
                        />
                    </a>
                    <a target="_blank" href="https://hfh.fas.harvard.edu/">
                        <img
                            className=""
                            style={{height: 75, position: "relative", top: 1}}
                            src="/img/logo3.png"
                            alt="Harvard"
                        />
                    </a>
                </div>
            </div>
            <div className="flex-col-2 flex-col-4">
                <div className="overlap-group2-1">
                    <div className="overlap-group1-2">
                        <p className="sign-up-to-our-newsletter instrumentserif-normal-white-34px">
                            Sign up to our newsletter
                        </p>
                    </div>
                    <div className="email-input">
                        <div className="button">
                            <input
                                className="enter-email-adress instrumentsans-normal-white-26px"
                                type="text"
                                placeholder="Enter email address"
                            />
                        </div>
                        <div className="button-1 button-4">
                            <div className="text-13 instrumentsans-normal-congress-blue-26px">
                                <svg
                                    width={19}
                                    height={18}
                                    viewBox="0 0 19 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0.0150782 7.704H17.1231L16.2131 8.432L7.89308 0.0599985H10.4931L18.9951 8.64L10.4671 17.22H7.86708L16.2131 8.848L17.1231 9.576H0.0150782V7.704Z"
                                        fill="#0A3887"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-links">
                    <div className="flex-col flex">
                        <div className="the-programme instrumentsans-normal-white-24px">
                            The Programme
                        </div>
                        <div className="overview instrumentsans-normal-white-24px">
                            <NavLink to="/course-overview">About</NavLink>
                        </div>
                        <div className="catalog instrumentsans-normal-white-24px">
                            <NavLink to="/course-catalogue">Modules</NavLink>
                        </div>
                        { !isLoggedIn() && (
                            <div className="catalog instrumentsans-normal-white-24px">
                                {" "}
                                <NavLink to={`${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${process.env.REACT_APP_IBL_BASE_DOMAIN}`}>Login </NavLink>
                            </div>
                        )}
                      
                    </div>
                    <div className="flex-col flex">
                        <div className="brought-to-you-by instrumentsans-normal-white-24px">
                            Brought to you by
                        </div>
                        <div className="oxford instrumentsans-normal-white-24px">
                            <a target="_blank" href="https://www.legatum.com/">
                                Legatum
                            </a>{" "}
                        </div>
                        <div className="harvard-1 instrumentsans-normal-white-24px">
                            <a target="_blank" href="https://oxfordcharacter.org/">
                                Oxford Character Project
                            </a>{" "}
                        </div>
                        <div className="legatum instrumentsans-normal-white-24px">
                            {" "}
                            <a target="_blank" href="https://hfh.fas.harvard.edu/">
                                The Human Flourishing
                                <br/> Program at Harvard
                            </a>{" "}
                        </div>
                    </div>
                    <div className="flex-col-3 flex-col-4">
                        <div className="support instrumentsans-normal-white-24px">
                            Support
                        </div>
                        <div className="place instrumentsans-normal-white-24px">
                            {" "}
                            <NavLink to="/contact"> Contact</NavLink>
                        </div>
                        <div className="privacy-policy instrumentsans-normal-white-24px">
                            
                            <a target="_blank" href="/privacy-policy">
                                Privacy Policy
                            </a>{" "}
                        </div>
                        <div className="cookies-policy instrumentsans-normal-white-24px">
                            Terms of Use
                        </div>
                    </div>
                </div>
                <div className="flex-row flex">
                    <div>
                        <p className="copyright-2023-leg instrumentsans-normal-white-12px">
                            Copyright © {currentYear} Legatum LTD. All rights reserved.<br/>
                            “Look within, lead beyond" is a trademark of Heart to Heart Leadership. It is used with permission.
                        </p>
                        <p className=" instrumentsans-normal-white-12px op-w">
                            “Look within, lead beyond" is a trademark of Heart to Heart
                            Leadership. It is used with permission.
                        </p>
                    </div>
                    <div className="socials">
                        <a href="https://x.com/LWCcourse" target={"_blank"}><img className="vector invert-icon"
                                                                   src="/img/x-twitter.png" alt="vector"/></a>
                        <a href={"https://www.linkedin.com/company/leadingwithcharacter/"} target={"_blank"}>
                            <img
                                className="iconlinkedin"
                                src="/img/icon-linkedin-1.svg"
                                alt="icon/linkedin"
                            />
                        </a>
                        <a href="https://www.youtube.com/@LeadingWithCharacter" target={"_blank"}>
                            <img
                                className="iconyoutube"
                                src="/img/icon-youtube-3.svg"
                                alt="icon/youtube"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </footer>

    );
}

export default Footer