import './HomePage.css'
import React, {useRef, useState} from "react";
import useHeaderFooter from "../../hooks/useHeaderFooter";
import CarouselCourseCard from '../../components/CarouselCourseCard/CarouselCourseCard';


interface HomePageProps {
    children?: React.ReactNode
}

const HomePage: React.FC<HomePageProps> = ({children}) => {

    const {headerContent, footerContent} = useHeaderFooter(children);
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);


    const handlePlay = () => {
        setIsPlaying(true);
        if (videoRef.current) {
            videoRef.current.play();

        }
    };


    return (
        <>
            <div className="container-center-horizontal">
                <div className="homepage-main screen">
                    {headerContent}
                    <div className="homepage">
                        <div className="overlap-group3">
                            <div className="hero-image">
                                <div className="overlap-group2">
                                    <div className="full-bleed-image">
                                        <div className="overlap-group"></div>
                                    </div>
                                    <div className="hero-image-1">
                                        <div className="overlap-group1">
                                            <div className="full-bleed-image-1">
                                                <div className="unsplash-container">
                                                    <img
                                                        className="unsplash-container-item"
                                                        src="/img/heroimg-retouch-01-1.png"
                                                        alt="papaioannou-kostas-tysecUm5HJA-unsplash 1"
                                                    />

                                                </div>
                                            </div>
                                            <img style={{height: "50vh"}}
                                                 className="graphic" src="/img/hero-g-2.svg" alt="Graphic"/>
                                            <div className="rectangle-21 rectangle"/>
                                            <div className="grad-1 grad-2"/>
                                            <div className="grad-for-nav"/>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* <img
                                className="a-joint-initiative-o"
                                src="/img/a-joint-initiative-of-legatum-and-the-universities-of-oxford-and.svg"
                                alt="A joint initiative of Legatum and the universities of Oxford and Harvard"
                            /> */}

                            <div className="home-intro-text">
                                <p>
                                    A joint initiative of <span>Legatum</span>, the Oxford Character Project at the <span>University
                                    of Oxford</span> and
                                    <br/> the Human Flourishing Program at <span>Harvard's Institute for
                                    Quantitative Social Science</span>.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='purple-home'>
                        <div className='homepageContainer'>
                            <div className="intro-block intro-block-custom">



                                <div className="intro-block-text">
                                    <p className="be-a-better-leader-build-a-better-world">
                                        Be a better leader
                                        <br />
                                        Build a better world
                                    </p>
                                    <p className="lorem-ipsum-dolor-si-1 inter-light-congress-blue-22px">
                                        Leading with Character is your guide to making a difference! Whether you are seeking to strengthen your local community, build a successful organisation, or address global challenges,  this course will help you to realise your potential as a leader.
                                    </p>
                                    <div className="home-btn"><a className="course-btn gs" href="/course-overview/course-v1:Legatum+100+2023"><span className={"btn-d-flex"}>Get Started <img
                                        className={"carousel-btn-icon"} src={"/img/arrow-right.svg"}/></span></a></div>
                                </div>


                                <div className="intro-video">
                                    <div className="overlap-group-1">
                                        <video
                                            className="intro-video-container"
                                            width={320}
                                            height={240}
                                            ref={videoRef}
                                            controls
                                            style={{ display: isPlaying ? 'block' : 'none' }}
                                        >
                                            <source
                                                src="/video/Leading With Character - Introduction.mp4"
                                                type="video/mp4"
                                            />
                                            Your browser does not support the video tag.
                                        </video>
                                        {!isPlaying && (
                                            <>
                                                <img
                                                    className="prince-akachi-i2ho-d"
                                                    src="/img/screenshot-2023-08-11-at-12-23-1.png"
                                                    alt="prince-akachi-i2hoD-C2RUA-unsplash_Extended 1"
                                                />
                                                <img
                                                    className="play"
                                                    src="/img/play@2x.png"
                                                    alt="Play"
                                                    onClick={handlePlay}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <div
                                                    className="ellipse-21"
                                                    onClick={handlePlay}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                    
                   
                 
                    <div className="overlap-group4">
                    

                        <div className="explore-courses">
                            <div className="explore-the-courses">Explore the course</div>
                            <div className='legatum-divider-container'>
                                <div className='legatum-divider'></div>
                            </div>
                            <div className='explore-the-courses' style={{marginTop : "0px"}}>
                                <p className="lorem-ipsum-dolor-si-1 inter-light-congress-blue-22px">Character is what sets the best leaders apart. Our 30-day course is based on cutting-edge research into character, leadership and human flourishing from two of the world’s leading educational institutions – the University of Oxford and Harvard University. This course will help you to develop character qualities to lead from where you are and build the personal habits that are at the heart of good leadership.</p>
                            </div>
                         
                            <div className="courses">
                                
                                    <CarouselCourseCard />


                              

                              
                            </div>
                        </div>

                        <div className="quote">
                            <div className="legatum-container display-flex testimonial-custom-gap">
                                <div className="quote-text quote-text-custom">
                                    “I would recommend this course to every person. It is a 'must' for leadership improvement.”
                                </div>
                                <div className="test-2">
                                    <img src='/img/c-logo/pablo-pic.jpeg' style={{borderRadius : "50%"}}/>
                                    <div className="simbe-kadarake instrumentserif-normal-congress-blue-48px">
                                        -Pablo Arreguín
                                    </div>
                                    <p className="quote-position">
                                        Organisational leader in STEM education at Scienko México
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='learning-journey'>
                            <div className="explore-the-courses">Learning Journey</div>
                            <div className='legatum-divider-container'>
                                <div className='legatum-divider'></div>
                            </div>
                            <div className='journey-container'>

                                <div>
                                    <p className="lorem-ipsum-dolor-si-1 inter-light-congress-blue-22px started">Developed by a team of leadership experts from around the world, our course is free and open to all. Learn and grow atyour own pace, anytime, anywhere.</p>
                                    <div className="home-btn"><a className="course-btn gs"
                                                                 href="/course-overview/course-v1:Legatum+100+2023"><span
                                        className={"btn-d-flex"}>Get Started <img
                                        className={"carousel-btn-icon"} src={"/img/arrow-right.svg"}/></span></a></div>
                                </div>

                                <p className="lorem-ipsum-dolor-si-1 inter-light-congress-blue-22px end">Our course features four core modules - each module offers 2-3 hours of engaging online content, followed by five days of practical exercises that take just 20 minutes a day.</p>

                            </div>

                         

                        </div>

                        <div style={{backgroundColor : "#FFF6F6"}}>
                            <div className='learning-journey-image homepageContainer'>
                                <img src="/img/learning_journey_svg.svg" style={{ width: '100%' }} />
                            </div>
                        </div>

                       

                        <div className="quote">
                            <div className="legatum-container display-flex testimonial-custom-gap">
                                <div className="quote-text quote-text-custom">
                                    “…change does not occur itself, somebody has to step out, to say something, to do something…I believe that change is possible, but that requires us to act.”
                                </div>
                                <div className="test-2">
                                    <img src='/img/c-logo/malala.jpg' style={{ borderRadius: "50%" }} />
                                    <div className="simbe-kadarake instrumentserif-normal-congress-blue-48px">
                                        -Malala Yousafzai
                                    </div>
                                    <p className="quote-position">
                                        International education advocate and youngest Nobel Peace Prize laureate in history
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="explore-courses">
                            <div className="explore-the-courses">Global Reach
                                <p className="lorem-ipsum-dolor-si-1 inter-light-congress-blue-22px" style={{ marginTop: '20px' }}>
                                    Leaders around the world are prioritising their character development. Larger circles indicate higher numbers of leaders accessing the course from each location.
                                </p>


                            </div>
                            <div className='learning-journey-image' style={{display : "none"}}>
                                <img src="/img/legatum_map.svg" />
                            </div>


                       
                         
                        </div>
                        


                        


                        {footerContent}
                    </div>
                </div>
            </div>

        </>
    )

}

export default HomePage