import {getUserName, IBLSpinLoader, useProfileCourses} from "@iblai/ibl-web-react-common";
import {NavLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {enabledCourseArray, findImmediateNextChallengeCourse, isEligibleToEnrollV2} from "../../hooks/utils/helpers";
import {useDebounce} from "../../hooks/useDebounce";
import './CoursesInProgress.css'
import {CourseCatalogueItem, useCourseCatalogue} from "../../hooks/useCourseCatalogue";

const CoursesInProgress = ({showOnProgress=true, title="Modules In Progress", showAll=false}) => {
    const [coursesData, courseDataLoaded] = useProfileCourses(
        getUserName()
    );
    const [allCoursesData, allCourseDataLoaded, allCourseMetaDataLoaded] = useCourseCatalogue();

    const [filtererdCourseData, setFilteredCourseData] = useState<any[]>([]);

    const [eligibilityStatus, setEligibilityStatus] = useState<{ [key: string]: boolean }>({});
    const [completedStatus, setCompletedStatus] = useState<{ [key: string]: boolean }>({});
    const [detailsLoaded, setDetailsLoaded] = useState<{ [key: string]: boolean }>({});
    const [courseStatusChecked, setCourseStatusChecked] = useState(false)
    const [coursesToDisplay, setCoursesToDisplay] = useState<any[]>([])
    const [fiveDayCoursesList, setFiveDayCoursesList] = useState<any[]>([])
    const [fiveDayCoursesCompletedStatus, setfiveDayCoursesCompletedStatus] = useState<{ [key: string]: boolean }>({});
    const [fiveDayCoursesStatusChecked, setfiveDayCoursesStatusChecked] = useState(false)

    const handleCheckCoursesStatus = async () => {
        if(showAll && !allCourseMetaDataLoaded){
            return
        }
        if(Array.isArray(coursesData)){
            setCourseStatusChecked(false)
            setfiveDayCoursesStatusChecked(false)
            const validCoursesIDs = enabledCourseArray.filter((pre)=>!pre.type).map((course)=>(course.course_id))
            const additionalCourses = showAll && allCourseMetaDataLoaded ? allCoursesData
                .filter((course) => validCoursesIDs.includes(course?.search?._source?.course_id || ""))
                .map((course) => ({
                    course:{
                        course_id:course?.search?._source?.course_id,
                        course_name:course?.search?._source?.name,
                    },
                    search: course?.search,
                    metadata:course?.metadata
                })) :[]
            const coursesToFilter = [
                ...(coursesData.filter((course) => validCoursesIDs.includes(course?.course?.course_id))),
                ...additionalCourses
            ]
            const _filteredCourseData : any[]= []
            validCoursesIDs.forEach((courseID)=>{
                const validCourse = coursesToFilter.find((pre)=>pre?.course?.course_id === courseID)
                if(validCourse){
                    _filteredCourseData.push(validCourse)
                }
           })
            setFilteredCourseData(_filteredCourseData)
            if(_filteredCourseData.length === 0){
                setCourseStatusChecked(true)
                setfiveDayCoursesStatusChecked(true)
                return
            }
            _filteredCourseData.forEach((courseInstance, index)=>{
                const courseId = courseInstance?.course?.course_id;
                if (courseId) {

                    isEligibleToEnrollV2(courseInstance?.metadata?.custom?.prerequisite, null, true).then((eligibleData : boolean | {eligible:boolean, completed:boolean}) => {
                        if (typeof eligibleData !== "boolean") {
                            setEligibilityStatus((prev) => ({
                                ...prev,
                                [courseId]: courseId === "course-v1:Legatum+100+2023" ? true : eligibleData.eligible,
                            }));
                        }
                        setDetailsLoaded((prev) => ({
                            ...prev,
                            [courseId]: true,
                        }));
                    });
                    isEligibleToEnrollV2(courseId, null, true).then((eligibleData : boolean | {eligible:boolean, completed:boolean}) => {
                        if (typeof eligibleData !== "boolean") {
                            setCompletedStatus((prev) => ({
                                ...prev,
                                [courseId]: eligibleData.completed,
                            }));
                            if(index===_filteredCourseData.length - 1){
                                setCourseStatusChecked(true)
                            }
                        }
                    });
                }
            })
        }else{
            setCourseStatusChecked(true)
            setfiveDayCoursesStatusChecked(true)
        }
    }

    useEffect(()=>{
        handleCheckCoursesStatus()
    }, [courseDataLoaded, coursesData, allCourseMetaDataLoaded])

    const handleCourseToDisplay = () => {
        if(courseStatusChecked && filtererdCourseData.length > 0){
            const validCoursesIDs = enabledCourseArray.filter((pre)=>!pre.type).map((course)=>(course.course_id))
            const coursesToShowcase =  showAll ? validCoursesIDs : Object.keys(completedStatus).filter(courseId => completedStatus[courseId] === !showOnProgress)
            if(coursesToShowcase.length===0){
                setCourseStatusChecked(true)
                setfiveDayCoursesStatusChecked(true)
                return
            }
            setCoursesToDisplay(coursesToShowcase)
            const _5dayCoursesList = coursesToShowcase.map((courseID)=>({
                ...findImmediateNextChallengeCourse(courseID),
                parentCourseID:courseID
            }))
            setFiveDayCoursesList(_5dayCoursesList)
            //const courses = completedStatus.
        }
    }

    const handle5DayChallengeCourseToDisplay = () => {
        const filteredFiveDayCoursesList = fiveDayCoursesList.filter((pre)=>pre?.course_id)
        if(fiveDayCoursesList.length > 0){
            if(filteredFiveDayCoursesList.length > 0){
                filteredFiveDayCoursesList.forEach((_5dayChallengeCourse, index)=>{
                    isEligibleToEnrollV2(_5dayChallengeCourse?.course_id, null, true).then((eligibleData : boolean | {eligible:boolean, completed:boolean}) => {
                        if (typeof eligibleData !== "boolean") {
                            setfiveDayCoursesCompletedStatus((prev) => ({
                                ...prev,
                                [_5dayChallengeCourse?.course_id]: eligibleData.completed,
                            }));
                            if(index===filteredFiveDayCoursesList.length - 1){
                                setfiveDayCoursesStatusChecked(true)
                            }
                        }
                    });
                })
            }else{
                setfiveDayCoursesStatusChecked(true)
            }

        }
    }

    useDebounce(handleCourseToDisplay, 200, [courseStatusChecked])

    useDebounce(handle5DayChallengeCourseToDisplay, 200, [fiveDayCoursesList])

    return (
        <>
            {
                !fiveDayCoursesStatusChecked ? <IBLSpinLoader size={45} containerHeight={"200px"} /> :
                    coursesToDisplay.length > 0 ?
                        (
                            <div className="courses-in-progress">
                                <div className="modules recklessneue-book-normal-congress-blue-38px">
                                    {title}
                                </div>
                                <div className="courses-slider" data-name="courses-in-progress">

                                    {Array.isArray(filtererdCourseData) && (showAll ? filtererdCourseData : filtererdCourseData.filter((courseInstance, index)=>coursesToDisplay.includes(courseInstance?.course?.course_id))).map((courseInstance, index) => {
                                        const courseId = courseInstance?.course?.course_id || "";
                                        const has5DayChallenge = typeof (fiveDayCoursesCompletedStatus?.[(fiveDayCoursesList.find((pre)=>pre?.parentCourseID===courseId && pre?.course_id)?.course_id)]) === "boolean"
                                        const _5DayCourseID = (fiveDayCoursesList.find((pre) => pre?.parentCourseID === courseId  && pre?.course_id)?.course_id)
                                        return (
                                            <div key={`course-item-${courseId}-${index}`} className="course-item">
                                                {
                                                    has5DayChallenge && (
                                                        <NavLink to={`/course-overview/${_5DayCourseID}`}
                                                                 className="_5daychallenge-banner">
                                                            <div className={"_5daychallenge-banner-details-block"}>
                                                                <div className={"_5daychallenge-banner-header"}>5 Day</div>
                                                                <div className={"_5daychallenge-banner-desc"}>Challenge
                                                                </div>
                                                            </div>
                                                            <div className={"_5daychallenge-icon-block"}>
                                                                <img alt={"Calendar Icon"} src={"/img/calendar-days.svg"}/>
                                                            </div>
                                                        </NavLink>
                                                    )
                                                }

                                                <div className="top-c">
                                                    <img
                                                        src={`${process.env.REACT_APP_IBL_LMS_URL}${courseInstance?.metadata?.course_image_asset_path}`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="c-name">
                                                    {courseInstance?.course?.course_name}
                                                </div>
                                                <p>
                                                    {courseInstance?.metadata?.description}
                                                </p>
                                                <div className="c-details">
                                                    <span className="it">Time :</span>{" "}
                                                    <span
                                                        className="reg">{courseInstance?.metadata?.duration}</span>{" "}
                                                    {detailsLoaded[courseId] && (
                                                        <>
                                                            <span className="c_line"/> <span
                                                            className="it">Access :</span>{" "}
                                                            {eligibilityStatus[courseId] ? (
                                                                <>
                                                <span id="access"
                                                      className="reg">{completedStatus[courseId] ? "Complete" : "Available Now"}</span>
                                                                    {enrollmentLink(courseId)}
                                                                </>
                                                            ) : (
                                                                <>
                                                            <span id="access"
                                                                  className="reg">Complete Previous Module</span>
                                                                    {enrollmentLink(courseId)}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                {
                                                    has5DayChallenge && (
                                                        <div className="c-details _5daychallenge-block">
                                                            <div className={"_5daychallenge-icon-block more-m-r"} >
                                                                <img alt={"Calendar Icon"} src={"/img/calendar-days.svg"} />
                                                            </div>
                                                            <span className="it">5 Day Challenge</span>{" "}
                                                            <span className="c_line"></span>
                                                            <>
                                                            <span id="access"
                                                                  className="reg">{fiveDayCoursesCompletedStatus?.[_5DayCourseID] ? "Closed" : "Available"}</span>
                                                                {enrollmentLink(_5DayCourseID)}
                                                            </>
                                                        </div>
                                                    )
                                                }

                                            </div>

                                        )
                                    })}


                                </div>
                            </div>

                        )
                        :
                        <></>

            }


        </>
    )
}

const enrollmentLink = (courseId: string) => (
    <NavLink to={`/course-overview/${courseId}`} className="c_button">
        <svg
            width={19}
            height={18}
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.0150782 7.704H17.1231L16.2131 8.432L7.89308 0.0599985H10.4931L18.9951 8.64L10.4671 17.22H7.86708L16.2131 8.848L17.1231 9.576H0.0150782V7.704Z"
                fill="#fff"
            />
        </svg>
    </NavLink>
);

export default CoursesInProgress