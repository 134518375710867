import React from "react";
import {getUserName, useProfileCredentials} from "@iblai/ibl-web-react-common";
import {NavLink} from "react-router-dom";

const Achievements = () => {

    function credentialImageURL(credential: any) {
        return (
            credential?.credentialDetails?.iconImage || '/img/default_badge.png'
        );
    }

    const {
        userCredentials,
        userCredentialsLoaded,
        discoverCredentials,
        discoverCredentialsLoaded,
    } = useProfileCredentials(getUserName());


    return (
        <>


            {
                userCredentialsLoaded && userCredentials.map((credential, index) => {
                    return (
                        <NavLink
                            className="cred-box"
                            to={`/certificate/${credential?.entityId}`}
                            target="_blank"
                        >
                            {" "}
                            <img
                                data-name="badge_image"
                                loading="lazy"
                                alt=""
                                src={credentialImageURL(credential)}
                                onError={(e) => (e.currentTarget.src = '/img/default_badge.png')}
                                className="image-16"
                            />
                            <div>
                                {credential?.credentialDetails?.name}
                                <div></div>
                            </div>
                        </NavLink>
                    )
                })
            }


        </>
    )
}

export default Achievements