import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { isLoggedIn, saveUserMetaData } from "../../hooks/utils/helpers";
import { AppDataContext, handleLogout } from "@iblai/ibl-web-react-common";
import { useNavigate } from "react-router-dom";
import "./Header.css"; // Make sure to create this CSS file

const _handleLogout = (event: SyntheticEvent) => {
  event.preventDefault();
  event.stopPropagation();
  handleLogout(window.location.origin);
};

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileAboutOpen, setMobileAboutOpen] = useState(false);
  const [mobileUserMenuOpen, setMobileUserMenuOpen] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);
  const [submenuOveralayVisible, setSubmenuOveralayVisible] = useState(false);

  useEffect(() => {
    const adjustNavbar = () => {
      if (navRef.current) {
        if (window.pageYOffset > 0) {
          navRef.current.classList.add("scrolled");
        } else {
          navRef.current.classList.remove("scrolled");
        }
      }
    };

    window.addEventListener("scroll", adjustNavbar);
    adjustNavbar();

    return () => {
      window.removeEventListener("scroll", adjustNavbar);
    };
  }, []);

  const { appData, setAppData } = useContext(AppDataContext);
  const navigate = useNavigate();

  useEffect(() => {
    const navigateToCourse = localStorage.getItem("navigateToCourse");
    /*if (navigateToCourse === null) {
            localStorage.setItem('navigateToCourse', 'true');
        }*/
    if (isLoggedIn() && !navigateToCourse && appData.userMetaDataLoaded) {
      // @ts-ignore
      const currentCourse =
        appData.userMetaData?.public_metadata?.current_course ||
        "course-v1:Legatum+100+2023";
      localStorage.setItem("navigateToCourse", "false");
      navigate(`/course-overview/${currentCourse}`);
    }
  }, [appData.userMetaData, appData.userMetaDataLoaded, navigate]);

  useEffect(() => {
    const consentedToDataCollection = localStorage.getItem(
      "consented_to_data_collection"
    );
    // @ts-ignore
    if (
      consentedToDataCollection !== null &&
      !appData.userMetaData?.public_metadata?.consented_to_data_collection
    ) {
      const userMetaData: any = {
        ...appData.userMetaData,
        public_metadata: {
          ...appData.userMetaData.public_metadata,
          consented_to_data_collection: consentedToDataCollection === "true",
        },
      };
      saveUserMetaData(
        userMetaData,
        (response: any) => {
          // Handle successful response if needed
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }, []);

  const aboutDropDownRef = useRef<HTMLDivElement | null>(null);
  const signInDropDownRef = useRef<HTMLDivElement | null>(null);
  const dashboardDropDownRef = useRef<HTMLDivElement | null>(null);

  const handleMouseEnter = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.classList.add("active");
      setSubmenuOveralayVisible(true);
    }
  };

  const handleMouseLeave = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.classList.remove("active");
      setSubmenuOveralayVisible(false);
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    if (navRef.current) {
      navRef.current.classList.toggle("mobile-nav-custom");
    }
  };

  const toggleMobileAbout = (e: React.MouseEvent) => {
    e.stopPropagation();
    setMobileAboutOpen(!mobileAboutOpen);
    setMobileUserMenuOpen(false);
  };

  const toggleMobileUserMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setMobileUserMenuOpen((prev) => !prev);

    setMobileAboutOpen(false);
  };

  return (
    <>
      <div
        className={`nav ${mobileMenuOpen ? "mobile-nav-custom" : ""}`}
        ref={navRef}
      >
        <div className="nav-cont">
          <NavLink to="/">
            {" "}
            <img
              className="legatum-header-logo"
              src="/img/legatum_logo_2.svg"
              alt="Logo Lockup"
            />{" "}
          </NavLink>
          <div className="navbar instrumentsans-normal-white-22-7px">
            <div
              className="navbar-link-courses navbar-link w-dropdown"
              data-name="about"
              onMouseEnter={() => handleMouseEnter(aboutDropDownRef)}
              onMouseLeave={() => handleMouseLeave(aboutDropDownRef)}
            >
              <NavLink to="#">
                About{" "}
                <svg
                  className="menu-drop"
                  width={17}
                  height={12}
                  viewBox="0 0 17 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.375"
                    y="0.543945"
                    width="15.609"
                    height="10.25"
                    rx="5.125"
                    stroke="white"
                    strokeWidth="0.75"
                  />
                  <path
                    d="M11.6733 4.45402L8.23438 7.04674L4.7955 4.45402"
                    stroke="white"
                    strokeWidth="0.75"
                  />
                </svg>
              </NavLink>

              <div
                className="drop-down about-dd"
                data-name="about"
                ref={aboutDropDownRef}
              >
                <NavLink to="/about"></NavLink>
                <div className="about-drop">
                  <NavLink to="/about">
                    <span> </span>
                  </NavLink>
                  <NavLink to="/course-overview">Overview</NavLink>
                  <span>
                    {" "}
                    <NavLink to="/brought-to-you-by">Brought to you by</NavLink>
                  </span>
                  <span>
                    {" "}
                    <NavLink to="/presented-by">Presented by</NavLink>
                  </span>
                </div>
              </div>
            </div>
            <NavLink to="/course-catalogue">
              <div className="navbar-link-about navbar-link">Modules</div>
            </NavLink>
            <NavLink to="/contact">
              <div className="navbar-link-about navbar-link">Contact</div>
            </NavLink>
            {!isLoggedIn() && (
              <NavLink
                to={`${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${process.env.REACT_APP_IBL_BASE_DOMAIN}`}
              >
                <div className="navbar-link-about navbar-link">Login</div>
              </NavLink>
            )}

            {isLoggedIn() && (
              <div
                className=" navbar-link w-dropdown logged-in"
                data-name="user-menu"
                style={{ display: "block" }}
                onMouseEnter={() => handleMouseEnter(dashboardDropDownRef)}
                onMouseLeave={() => handleMouseLeave(dashboardDropDownRef)}
              >
                <div className={"not-logged-in-user-container"}>
                  <div className={"not-logged-in-user-block"}>
                    <img src={"/img/user-round.svg"} />
                  </div>
                </div>
                <div
                  className="drop-down contact-dd user-menu-dd"
                  data-name="user-menu"
                  ref={dashboardDropDownRef}
                >
                  <div className="about-drop">
                    <span>
                      {" "}
                      <NavLink to="/profile-learner">Profile</NavLink>
                    </span>
                    <span>
                      {" "}
                      <NavLink to="/user-dashboard">Dashboard</NavLink>
                    </span>
                    <span>
                      {" "}
                      <NavLink
                        to="#"
                        onClick={(event) => _handleLogout(event)}
                        className="logout_btn"
                      >
                        Logout
                      </NavLink>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mobile_toggle navbar-link" onClick={toggleMobileMenu}>
            <i className="fas fa-bars" />
            <div
              className={`mobile_menu ${mobileMenuOpen ? "active" : ""}`}
              style={{ height: "auto" }}
            >
              <div className="navbar-link-courses navbar-link w-dropdown">
                <div onClick={toggleMobileAbout}>
                  About{" "}
                  <svg
                    className="menu-drop"
                    width={17}
                    height={12}
                    viewBox="0 0 17 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.375"
                      y="0.543945"
                      width="15.609"
                      height="10.25"
                      rx="5.125"
                      stroke="white"
                      strokeWidth="0.75"
                    />
                    <path
                      d="M11.6733 4.45402L8.23438 7.04674L4.7955 4.45402"
                      stroke="white"
                      strokeWidth="0.75"
                    />
                  </svg>
                </div>
                <div
                  className={`drop-down about-dd ${
                    mobileAboutOpen ? "active" : ""
                  }`}
                  data-name="about"
                >
                  <div className="about-drop">
                    <NavLink to="/course-overview">Overview</NavLink>
                    <NavLink to="/brought-to-you-by">Brought to you by</NavLink>
                    <NavLink to="/presented-by">Presented by</NavLink>
                  </div>
                </div>
              </div>
              <NavLink to="/course-catalogue">
                <div className="navbar-link-about navbar-link">Modules</div>
              </NavLink>
              <NavLink to="/contact">
                <div className="navbar-link-about navbar-link">Contact</div>
              </NavLink>

              {!isLoggedIn() && (
                <NavLink
                  to={`${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${process.env.REACT_APP_IBL_BASE_DOMAIN}`}
                >
                  <div className="navbar-link-about navbar-link">Login</div>
                </NavLink>
              )}
              {isLoggedIn() && (
                <div
                  className="overlap-group1-1 navbar-link w-dropdown"
                  data-name="user-menu"
                  onClick={(e) => toggleMobileUserMenu(e)}
                >
                  <div className="ellipse-container">
                    <img
                      className="ellipse-10"
                      src="/img/ellipse-10-1.svg"
                      alt="Ellipse 10"
                    />
                    <div className="ellipse-11" />
                  </div>
                  <div className="ellipse-12" />
                  <div
                    className={`drop-down contact-dd user-menu-dd ${
                      mobileUserMenuOpen ? "active" : ""
                    }`}
                    data-name="user-menu"
                  >
                    <div className="about-drop">
                      <NavLink to="/profile-learner">Profile</NavLink>
                      <NavLink to="/user-dashboard">Dashboard</NavLink>
                      <NavLink
                        to="#"
                        className="logout_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleLogout(window.location.origin);
                        }}
                      >
                        Logout
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {submenuOveralayVisible && (
        <div className={"header-submenu-overlay"}></div>
      )}
    </>
  );
};

export default Header;
